import React from 'react';
import Helmet from 'react-helmet';
import Nav from './nav';
import Footer from './footer';
import Title from './title';
import Notifications from './notifications';
import Offers from './offers';
import FavIcon from '../images/favicon.ico';
import Snipcart from 'pourmgmt/snipcart';
import 'pour-styles';
import '../styles';

export default class Component extends React.Component {
  state = {numItems: 0};

  componentDidMount() {
    Snipcart.init({site: 'iconic'}, (state) => this.setState(state));
  }

  render() {
    return (
      <div>
        <Helmet title="Iconic Wines - Wine crafted on the Shoulders of Giants" htmlAttributes={{lang: 'en'}}>
          <link rel="shortcut icon" href={FavIcon} type="image/x-icon" />
        </Helmet>
        <Nav {...this.props} {...this.state} />
        <Title {...this.props} />
        {this.props.nav == 'shop' && <div style={{marginTop: '15px'}}><Notifications {...this.props} {...this.state} /></div>}
        {this.props.nav == 'shop' && <div><Offers {...this.props} {...this.state} /></div>}
        <div style={{padding: this.props.padding || '20px 10px 20px 10px'}}>
          {this.props.children}
        </div>
        {!this.props.noFooter && <Footer {...this.props} />}
      </div>
    )
  }
}
