import React from 'react';
import { Link } from "gatsby"
import { Collapse } from 'react-collapse';
import LogoImage from '../images/logo.png'

export default (props) => (
      <header className="header header-2 sticky-header" style={{padding: 0}}>
          <div className="header-top">
              <div className="container-fluid" style={{paddingRight: 0}}>
                  <div className="row">
                    <FullNav {...props} />
                    <MobileNav {...props}/>
                  </div>
              </div>
          </div>
      </header>
)

const FullNav = (props) => (
  <div className="hidden-sm hidden-xs" style={{padding: '0 55px'}}>
    <div className="col-md-2 col-sm-2 col-xs-12">
        <div className="logo text-left">
            <Link to="/">
              <img src={LogoImage} alt="Return home" style={{height: '50px'}}/>
            </Link>
        </div>
    </div>
    <div className="col-md-8">
        <div className="menu text-center">
            <nav>
                 <ul style={{paddingLeft: 0}}>
                    <li style={props.nav === 'about' ? {borderBottom: '3px solid #000', marginLeft: 0, fontSize: '14px'} : {marginLeft: 0, fontSize: '14px'}}><Link to="/about" style={{paddingBottom: 0}}>About</Link></li>
                    <li style={props.nav === 'shop' ? {borderBottom: '3px solid #000', marginLeft: '30px', fontSize: '14px'} : {marginLeft: '30px', fontSize: '14px'}}><Link to="/shop" style={{paddingBottom: 0}}>Wines</Link></li>
                    <li style={props.nav === 'events' ? {borderBottom: '3px solid #000', marginLeft: '30px', fontSize: '14px'} : {marginLeft: '30px', fontSize: '14px'}}><Link to="/events" style={{paddingBottom: 0}}>Events</Link></li>
                    <li style={props.nav === 'trade' ? {borderBottom: '3px solid #000', marginLeft: '30px', fontSize: '14px'} : {marginLeft: '30px', fontSize: '14px'}}><Link to="/trade" style={{paddingBottom: 0}}>Trade</Link></li>
                    <li style={props.nav === 'contact' ? {borderBottom: '3px solid #000', marginLeft: '30px', fontSize: '14px'} : {marginLeft: '30px', fontSize: '14px'}}><Link to="/contact" style={{paddingBottom: 0}}>Contact</Link></li>
                    {props.numItems ? <li>
                      <a href="#" className="snipcart-checkout" style={{lineHeight: 'inherit', padding: 'inherit'}}>
                      <i className="fa fa-shopping-cart" style= {{color: '#000', fontSize: '24px', marginLeft: '10px', top: 0}}></i>
                      <span className="cart-count" style={{background: '#000 none repeat scroll 0 0', color: '#fff', borderRadius: '50%', fontSize: '12px', height: '18px', position: 'absolute', left: '35px', textAlign: 'center', width: '18px', top: '7px', display: 'inline-block', fontWeight: 800}}>{props.numItems}</span>
                      </a>
                    </li> : null}
                </ul>
            </nav>
        </div>
    </div>
  </div>
)

class MobileNav extends React.Component {
  state={open: false};
  render() {
    return (
  <div className="basic-mobile-menu hidden-lg hidden-md visible-xs">
      <div style={{margin: '0px 15px'}}>
        <div className="logo text-left" style={{float: 'left', padding: '15px 0'}}>
            <Link to="/">
              <img src={LogoImage} alt="Return home" style={{height: '50px'}}/>
            </Link>
        </div>
        <div style={{float: 'right', paddingTop: '16px', paddingRight: '5px', fontSize: '36px'}}>
          <a href="#" onClick={() => this.setState({open: !this.state.open})} aria-label="Open menu"><i className="fa fa-bars" style={{color: '#000'}}/></a>
        </div>
      </div>
      <div className="clearfix"></div>
      <Collapse isOpened={this.state.open}>
        <div style={{backgroundColor: '#000', color: '#fff', margin: '0px', padding: '20px 0px', textAlign: 'center'}}>
        <ul>
          <li style={{marginBottom: '15px'}}><Link to="/about" onClick={() => this.setState({open: !this.state.open})} style={{fontSize: '28px', color: '#fff'}}>ABOUT</Link></li>
          <li style={{marginBottom: '15px'}}><Link to="/shop" onClick={() => this.setState({open: !this.state.open})} style={{fontSize: '28px', color: '#fff'}}>WINES</Link></li>
          <li style={{marginBottom: '15px'}}><Link to="/events" onClick={() => this.setState({open: !this.state.open})} style={{fontSize: '28px', color: '#fff'}}>EVENTS</Link></li>
          <li style={{marginBottom: '15px'}}><Link to="/trade" onClick={() => this.setState({open: !this.state.open})} style={{fontSize: '28px', color: '#fff'}}>TRADE</Link></li>
          <li style={{marginBottom: '15px'}}><Link to="/contact" onClick={() => this.setState({open: !this.state.open})} style={{fontSize: '28px', color: '#fff'}}>CONTACT</Link></li>
          {this.props.numItems ?
          <li style={{marginBottom: '15px'}}><a href="#" onClick={() => this.setState({open: !this.state.open})} className="snipcart-checkout" style={{fontSize: '28px', color: '#fff', fontFamily: 'Fjalla One,sans-serif', textTransform: 'uppercase'}}>Cart ({this.props.numItems})</a></li>
          : null }
        </ul>
        </div>
      </Collapse>
  </div>
    )
  }
}
