import React from 'react';

export default class Signup extends React.Component {
  state = {submitted: false, email: ''};

  getData = () => {
    let data = {
      email: this.state.email,
      list: this.props.list,
      source: this.props.source,
      notify: this.props.notify
    };
    return new Promise((resolve, reject) => {
      fetch('https://ipapi.co/json/', {
        cache: 'no-cache',
        credentials: 'same-origin',
        method: 'GET', mode: 'cors', redirect: 'follow', referrer: 'no-referrer',
      }).then((res) => res.json().then(geo => {
        data.ip = geo.ip;
        data.city = geo.city;
        data.region = geo.region_code;
        data.country = geo.country;
        resolve(data);
      }).catch(err => reject(err)).catch(err => reject(err)));
    })
  }

  submit = (e) => {
    e.preventDefault();
    this.getData().then(data => {
      fetch('https://api.pourmgmt.com/commerce/subscribe', {
        body: JSON.stringify(data),
        cache: 'no-cache',
        credentials: 'same-origin',
        headers: { 'content-type': 'application/json' },
        method: 'POST', mode: 'cors', redirect: 'follow', referrer: 'no-referrer',
      }).then((res) => {
      }).catch((err) => {this.setState({signup: 'error'})});
      this.setState({submitted: true});
    })
  }

  render() {
    let color = this.props.dark ? '#fff' : '#000'
    if (this.state.submitted)
      return (
          <p style={{textAlign: 'center', fontSize: '18px', fontWeight: 600, color: color}}>Awesome - you&apos;re on the list</p>
      )
    return (
        <div className="newsletter-form">
          <div id="mc_embed_signup">
            <form className="validate">
              <div>
                <input value={this.state.email} onChange={(e) => this.setState({email: e.target.value})} style={{color: color, border: `2px solid ${color}`}}
                type="email" className="email" placeholder="Enter your email to signup for updates" required id="signup"/>
              <div className="clear">
                <button onClick={this.submit} className="button" style={{borderColor: color}}>Subscribe</button>
              </div>
            </div>
          </form>
        </div>
      </div>
)
}
}
