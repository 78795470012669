import React from 'react';
import LoadOrder from './order';
import './styles.js';
const WG = typeof window !== 'undefined' && window;

const init = (options, setState) => {
  if (!(WG && WG.Snipcart)) {
    console.log('Snipcart was not loaded');
    return;
  }
  setState({numItems: countItems()});
  WG.Snipcart.api.cart.update({metadata: { site: options.site }});
  const triggers = ['snipcart.initialized', 'item.added', 'item.updated', 'item.removed', 'cart.confirmed'];
  triggers.forEach(trigger => WG.Snipcart.events.on(trigger, (p) => setState({numItems: countItems()})) );
  if (window.location.search && window.location.search.length)
    LoadOrder();
}

const countItems = () => {
  if (!(WG && WG.Snipcart))
    return;
  const {cart} = WG.Snipcart.store.getState();
  var numItems = 0;
  cart.items && cart.items.items && cart.items.items.forEach((item) => numItems += item.quantity);
  return numItems;
}

export default {init, countItems};
