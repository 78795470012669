import React from 'react';
import { Link } from 'gatsby';
import {Row, Col} from 'react-bootstrap';

export default (props) => {
    return (
    <div style={{...styles.notification, borderTop: !props.numItems ? '2px solid #3f3f3f' : null }}>
      <div>
        <i style={{...styles.h3, marginRight: '20px', color: props.numItems < 6 ? null : 'green'}} className="fa fa-glass" />
        <h3 style={{...styles.h3, color: props.numItems < 6 ? null : 'green'}}>Check out our new project <a href="https://www.rockwellvermouth.com" target="_blank">Rockwell Vermouth</a></h3>
      </div>
    </div>
    )
}

const styles = {
  notification: {
    backgroundColor: '#f7f6f7',
    //borderTop: '2px solid #3f3f3f',
    borderBottom: '2px solid #3f3f3f',
    listStyle: 'outside none none !important',
    margin: 0,
    //marginBottom: '10px',
    position: 'relative',
    width: 'auto',
    padding: '10px 20px',
    textAlign: 'center'
  },
  h3: {
    color: '#515151',
    fontWeight: 500,
    fontSize: '16px',
    display: 'inline',
    lineHeight: '36px'
  }
}
