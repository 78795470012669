import React from 'react';
import { Link } from 'gatsby';
import {Row, Col} from 'react-bootstrap';

export default (props) => {
  var shippingMessage = "You've unlocked free shipping!";
  var discountMessage = null//cart && cart.total < 200 ? `10% OFF ORDERS OVER $200 - Add $${200 - cart.total} for discount` : "You've got a 10% discount!";
  if (props.numItems < 2)
    shippingMessage = `Add 1 more bottle to checkout (...cause wine is like, heavy)`
  else if (props.numItems < 6)
    shippingMessage = `Add ${6-props.numItems} more bottle${props.numItems > 1 ? 's': ''} for free shipping`;
  if (!props.numItems)
    return null;
  else
    return (
    <div style={styles.notification}>
      <div>
        <i style={{...styles.h3, marginRight: '20px', color: props.numItems < 6 ? null : 'green' }} className="fa fa-truck" />
        <h3 style={{...styles.h3, color: props.numItems < 6 ? null : 'green'}}>{shippingMessage}</h3>
      </div>
      {discountMessage && <div>
        <i style={{...styles.h3, marginRight: '23px', color: props.numItems < 12 ? null : 'green' }} className="fa fa-percent" />
        <h3 style={{...styles.h3, color: props.numItems < 12 ? null : 'green' }}>{discountMessage}</h3>
      </div>}
    </div>
    )
}

const styles = {
  notification: {
    backgroundColor: '#f7f6f7',
    borderTop: '2px solid #3f3f3f',
    borderBottom: '2px solid #3f3f3f',
    listStyle: 'outside none none !important',
    margin: 0,
    //marginBottom: '10px',
    position: 'relative',
    width: 'auto',
    padding: '10px 20px',
    textAlign: 'center'
  },
  h3: {
    color: '#515151',
    fontWeight: 500,
    fontSize: '16px',
    display: 'inline',
    lineHeight: '36px'
  }
}
