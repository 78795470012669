import { navigate } from "gatsby"
const WG = typeof window !== 'undefined' && window

export default () => {
  var order = getUrlParameter('order');
  if (order && order.length) {
  navigate("/")
  fetch(`https://api.pourmgmt.com/pals/public?orderId=${order}`).then(data => {
    data.json().then(order => {
      WG.Snipcart.api.items.clear();
      if (!order) {
        console.log('Sorry this order has already been completed');
        return;
      }
      // Add items
      for (let i = 0; i < order.items.length; i++) {
        var product = order.items[i];
        WG.Snipcart.api.items.add({
          id: product.SKU || product.id,
          name: product.Name ? (product.Name + ' ' + product.Vintage) : product.name,
          quantity: product.quantity,
          url: product.Url ? (process.env.BASE_URL + product.Url) : product.url,
          price: (product['Retail Price']) || product.price,
          image: (product['Product Shots'] && product['Product Shots'][0] && product['Product Shots'][0].url) || product.image,
          metadata: product.Type ? `{"type": "${product.Type}"}` : product.metadata,
        })
      }
      // Add promos
      var promos = order.promos || order.discounts;
      for (let i = 0; i < promos.length; i++)
        WG.Snipcart.api.discounts.applyDiscountCode(promos[i].code);
      // Add metadata
      WG.Snipcart.api.cart.update({
          metadata: {
            pourOrderId: order.id,
            pourUserId: order.pourUserId
          }
      })
      // Open Cart
      WG.Snipcart.api.modal.show();
    })
  }).catch(err => {})
  }
}

function getUrlParameter(name) {
    name = name.replace(/[\[]/, '\\[').replace(/[\]]/, '\\]');
    var regex = new RegExp('[\\?&]' + name + '=([^&#]*)');
    var results = regex.exec(WG && WG.location.search);
    results = results === null ? '' : decodeURIComponent(results[1].replace(/\+/g, ' '));
    return results;
};
