import React from 'react';
import { Link } from 'gatsby';
import Signup from 'pourmgmt/signup';
import {Row, Col} from 'react-bootstrap';

export default (props) => (
  <Row style={{backgroundColor: '#000', padding: '30px 10px 20px 10px'}}>
    <Col md={6} mdOffset={3}>
      <div className="newsletter-inner text-center newsletter-title" style={{marginBottom: 0}}>
          <p style={{marginBottom: '30px'}}><label for="signup" style={{color: '#fff'}}>Sign up to get hear about events and upcoming releases</label></p>
          <Signup dark source="www.iconicwine.com" list="c13112cdfd" notify />
          <div style={{textAlign: 'center', color: 'white', fontSize: '22px', marginTop: '30px', marginBottom: '20px'}}>
            <a href="https://www.facebook.com/iconicwine" target="_blank" aria-label="Facebook Link"><i className="fa fa-facebook" style={{textAlign: 'center', marginRight: '20px', color: '#fff'}}></i></a>
            <a href="https://www.twitter.com/iconicwine" target="_blank" aria-label="Twitter Link"><i className="fa fa-twitter" style={{textAlign: 'center', marginRight: '20px', color: '#fff'}}></i></a>
            <a href="https://www.instagram.com/iconicwine" target="_blank" aria-label="Instagram Link"><i className="fa fa-instagram" style={{textAlign: 'center', marginRight: '20px', color: '#fff'}}></i></a>
            <Link to="/contact" aria-label="Contact us Link"><i className="fa fa-envelope" style={{textAlign: 'center', color: '#fff'}} alt="Contact Form Link"></i></Link>
          </div>
          <p>Iconic Wines is backed by <a href="https://www.pourmgmt.com" target="_blank" style={{color: '#fff'}}>Pour Management</a></p>
      </div>
    </Col>
  </Row>
)
