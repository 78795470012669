import React from 'react';
import {Row, Col} from 'react-bootstrap';

export default (props) => (
  <Row>
    <Col md={12}>
      <div class="breadcrumbs-inner ">
        <div class="breadcrumbs-title text-center">
          <div class="title-opacity">
            {props.nav ? <h1 style={{fontSize: '100px', lineHeight: '80px'}}>{props.nav}</h1> : null}
          </div>
          <div class="breadcrumbs-title2">
            {Headers[props.nav] ? <h1 style={{fontSize: '40px'}}>{Headers[props.nav]}</h1> : null } 
          </div>
        </div>
      </div>
    </Col>
  </Row>
)

const Headers = {
  about: 'Every heroine has an origin',
  ramblings: 'Deep thoughts about grapes',
  shop: 'Lightning in a bottle',
  events: 'Find us in the wild',
  trade: 'All those little details',
  appointments: 'Behind the curtain',
  contact: "Say hello, we won't bite"
}
